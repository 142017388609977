import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Http from "../../Common/Http";
import IsLogin from "../../Common/IsLogin";
import { LoaderComponent } from "../../Common/LoaderComponent";

const Reports = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [receiveData, setReceiveData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/login") {
      fetchData();
    }
  }, [location.pathname]);

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
  };

  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Reports</h5>
        </div>
        {/* am-pagetitle */}
        <div className="am-pagebody" style={{ minHeight: "80vh" }}>
          <div className="row row-sm mg-t-15 mg-sm-t-20">
            {(receiveData === "admin" || receiveData === "hr") && (
              <div className="col-md-6">
                <div className="card bd-0">
                  <div className="card-header card-header-default bg-primary">
                    Hr Managment Report
                  </div>
                  <div className="card-body bd bd-t-0 rounded-bottom">
                    <div className="report_style">
                      <div className="report-item">
                        <Link to="/hr-salary-structure">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Salary Structure</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/hr-leave-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Leave Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/monthly-salary-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Monthly Salary Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/attendance-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Attendance Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/salary-payment-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Paid Salary Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/apa-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>APA Report</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
            )}
            {/* col-6 */}
            {(receiveData === "admin" || receiveData === "accounts") && (
              <div className="col-md-6">
                <div className="card bd-0">
                  <div className="card-header card-header-default bg-primary">
                    Accounts
                  </div>
                  <div className="card-body bd bd-t-0 rounded-bottom">
                    <div className="report_style">
                      <div className="report-item">
                        <Link to="/chart-of-account-ledger-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Chart Of Account Ledger Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/sub-category-list">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Subcategory Ledger Report</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/vendor-supplier-payable-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Vendor/Supplier Payable</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/distributor-payable-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Distributor Payable</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/receipt-payment-summary-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Receipts & Payments Summary</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/vendor-supplier-receivable-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Vendor/Supplier Receivable</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/distributor-receivable-report">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Distributor Receivable</span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="/trial-balance">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Trial Balance</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(receiveData === "depot" || receiveData === "supply_chain") && (
              <div className="col-md-6">
                <div className="card bd-0">
                  <div className="card-header card-header-default bg-primary">
                    Depot Report
                  </div>
                  <div className="card-body bd bd-t-0 rounded-bottom">
                    <div className="report_style">
                      {receiveData === "supply_chain" && (
                        <>
                          <div className="report-item">
                            <Link to="/report/depo-wise-raw-material-stock">
                              <i className="fa fa-globe" aria-hidden="true"></i>
                              <span>Raw Material Stock</span>
                            </Link>
                          </div>
                          <div className="report-item">
                            <Link to="/report/depo-wise-packing-material-stock">
                              <i className="fa fa-globe" aria-hidden="true"></i>
                              <span>Packing Material Stock</span>
                            </Link>
                          </div>
                          <div className="report-item">
                            <Link to="/report/depo-wise-product-stock">
                              <i className="fa fa-globe" aria-hidden="true"></i>
                              <span>Finished Good Stock</span>
                            </Link>
                          </div>
                        </>
                      )}
                      {receiveData === "depot" && (
                        <div className="report-item">
                          <Link to="/report/product-stock">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Product Stock</span>
                          </Link>
                        </div>
                      )}
                      <div className="report-item">
                        <Link to="/report/distributor">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>Distributors</span>
                        </Link>
                      </div>
                      {/* <div className="report-item">
                          <Link to="#">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Stock Breakdown</span>
                          </Link>
                        </div>
                        <div className="report-item">
                          <Link to="#">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Distributor Outstanding Summary</span>
                          </Link>
                        </div>
                        <div className="report-item">
                          <Link to="#">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Transfer History</span>
                          </Link>
                        </div>
                        <div className="report-item">
                          <Link to="#">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Return History</span>
                          </Link>
                        </div>
                        <div className="report-item">
                          <Link to="#">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            <span>Damage Return</span>
                          </Link>
                        </div> */}
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
            )}

            {receiveData === "supply_chain" && (
              <div className="col-md-6">
                <div className="card bd-0">
                  <div className="card-header card-header-default bg-primary">
                    Sales Report
                  </div>
                  <div className="card-body bd bd-t-0 rounded-bottom">
                    <div className="report_style">
                      <div className="report-item">
                        <Link to="#">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>
                            Monthly End SKU wise Sales (Payment Date Wise)
                          </span>
                        </Link>
                      </div>
                      <div className="report-item">
                        <Link to="#">
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span>
                            Monthly End SKU wise Sales (Delivery Date Wise)
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
            )}
          </div>
          {/* row */}
        </div>
      </div>
      {/* am-mainpanel */}
    </div>
  );
};

export default Reports;
